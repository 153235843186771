.YoY-color {
  color: #0b7bff !important;
}
.sector-table tbody tr td {
  border-bottom: 0px !important;
  border-right: 0px !important;
}

.sector-table thead tr th {
  border-bottom: 0px !important;
  border-right: 0px !important;
}

.chemical-main {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px 0;
  width: 100%;
  display: inline-block;
}
