.technical-block {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px auto 0;
  width: 100%;
  display: inline-block;
}
.technical-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .technical-block {
  background-color: #ffffff;
}
.dashboard-main.dark .technical-block .title-block h4 {
  color: var(--table-heading-block-color);
}
.technical-block {
  overflow: hidden;
}
.technical-block .data-block {
  background: #ffffff;
}
.technical-block .data-block .technical-chart {
  min-height: 700px;
  width: 100%;
}
.technical-block .data-block .technical-chart canvas {
  font-size: 10px;
  /* padding: 2px; */
}
@media only screen and (max-width: 991px) {
  .technical-block .data-block .technical-chart {
    min-height: 350px;
  }
}
