.balance-main {
  font-size: 18px;
  border-top: 0.25px solid var(--light-border-color);
  border-bottom: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px 0;
  width: 100%;
  display: inline-block;
}
.balance-main table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  overflow: hidden;
  border-radius: 5px;
}
.balance-main table thead tr,
.balance-main table tbody tr {
  border: 0.25px solid var(--light-border-color);
}
.balance-main table thead tr {
  border-top: 0px;
}
.balance-main table tbody:last-child tr {
  border-bottom: 0px;
}
.balance-main table tbody tr.empty-row {
  border: none;
}
.balance-main table thead tr th {
  border-top: none;
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  border-bottom: 0.25px solid var(--sharp-border-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  text-align: var(--table-heading-block-text-align);
  padding: var(--table-heading-block-inner-space);
  color: var(--table-heading-block-color);
}
.dashboard-main.dark .balance-main table thead tr th {
  color: #ffffff;
}
.balance-main table thead tr th:first-child {
  text-align: left;
}
.balance-main table thead tr th:last-child,
.balance-main table thead tr td:last-child {
  border-right: 0px;
}
.balance-main table tbody tr:last-child td {
  border-bottom: 0px;
}
.balance-main table tbody tr.bg-td {
  background-color: var(--light-gray);
  font-weight: 800;
}
.balance-main table tbody tr.bg-td td {
  font-size: 12px;
  /* text-transform: capitalize; */
  color: var(--light-black);
}
.balance-main table tbody tr.bold {
  font-weight: 800;
}
.balance-main table tbody tr.bold td:first-child {
  font-weight: 800;
  text-transform: capitalize;
}
.balance-main table tbody tr td {
  border-bottom: 0.25px solid var(--light-border-color);
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  text-align: var(--table-cell-text-align);
  padding: var(--table-cell-inner-space);
  border-top: 0px;
  color: var(--table-cell-color);
  text-align: var(--table-cell-text-align);
  font-weight: var(--table-cell-weight);
}
.dashboard-main.dark .balance-main table tbody tr td {
  color: #ffffff;
}
.dashboard-main.dark .balance-main table tbody tr.bg-td td {
  color: #222222;
}
.balance-main table tbody tr td:first-child {
  text-align: var(--table-label-column-text-align);
  font-weight: var(--table-label-column-weight);
  color: var(--table-label-column-color);
  font-size: var(--table-label-column-font-size);
}
.balance-main table tbody tr td:last-child {
  border-right: none;
}
@media only screen and (max-width: 991px) {
  .balance-main {
    overflow: auto;
  }
}
