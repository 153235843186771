.main-tabs-view {
  /* font-size: 18px; */
  /* border: 0.25px solid #3969D5; */
  /* border-radius: 5px; */
  margin: 5px auto;
  width: 100%;
  display: inline-block;
}
.main-tabs-view .nav.nav-tabs {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 0px;
  /* border: 0.25px solid var(--light-border-color); */
  /* background-color: var(--light-gray); */
  /* background-color: #c8e945; */
  border-radius: 5px;
}
.nav.nav-tabs .nav-item button.nav-link {
  /* font-size: 18px; */
  /* color: var(--light-black); */
  color: #000000;
  font-weight: 500;
  padding: 5px 10px;
  min-width: 150px;
  background: transparent;
  background-color: #c8e945;
  margin: 0;
  font-size: 13px !important;
  border-radius: 5px;
}
.nav.nav-tabs .nav-item button.nav-link.active {
  color: #ffffff;
  background-color: #0b254a;
  /* background-color: rgb(57 109 213); */
  border-radius: 5px;
}
.dashboard-main.dark .nav.nav-tabs .nav-item button.nav-link {
  /* color: #ffffff; */
  color: rgb(57 109 213);
}
.dashboard-main.dark .nav.nav-tabs .nav-item button.nav-link.active {
  /* border-color: #222222; */
  color: #ffffff;
}
.tab-content > .tab-pane {
  height: 0px;
  display: block;
}
.tab-content .tab-pane.active {
  height: auto;
}
