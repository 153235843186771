:root {
  --light-black: #484848e8;
  --white: #ffffff;

  --light-border: 0.25px solid rgb(57 109 213 / 30%);
  --light-border-color: rgb(57 109 213 / 20%);

  --shart-border-color: #396dd575;
  --sharp-border-color: #396dd575;
  /* --shart-border-color: rgb(57 109 213 / 100%);
  --sharp-border-color: rgb(57 109 213 / 100%); */

  --light-gray: #f7f7f7;

  --table-heading-m: 12px;
  /* --table-heading-inner-space: 12px 8px; */
  --table-heading-inner-space: 6px;
  --table-heading-weight: 600;
  --table-heading-color: #484848e8;
  /* --table-heading-color: #3d3d3d; */
  /* --table-heading-font-size: 15px; */
  --table-heading-font-size: 12px;
  --table-heading-text-align: right;

  /* --table-cell-inner-space: 6px 8px; */
  --table-cell-inner-space: 6px;
  --table-cell-color: #484848e8;
  /* --table-cell-color: #3d3d3d; */
  /* --table-cell-weight: 600;    */
  --table-cell-weight: 0;
  --table-cell-text-align: right;
  --table-cell-font-size: 11px;

  --table-label-column-color: #484848e8;
  --table-label-column-weight: 500;
  --table-label-column-text-align: left;
  --table-label-column-font-size: 12px;
  --table-label-column-inner-spacing: 10px 7px;

  --table-heading-block-m: 12px;
  /* --table-heading-block-inner-space: 12px 8px; */
  --table-heading-block-inner-space: 9px;
  --table-heading-block-weight: 600;
  --table-heading-block-color: #000000;
  /* --table-heading-block-color: #484848e8;*/
  /* --table-heading-block-color: #3d3d3d; */
  --table-heading-block-font-size: 15px;
  --table-heading-block-text-align: right;

  --td-inner-spacing: 7px 10px;
  --charts-block-shadow: 0 0 10px #67b7dc63;
}

.table-unit-col {
  font-size: 9px;
}

* {
  font-family: 'Lato', sans-serif !important;
}

body {
  min-height: 100vh;
  background: #ffffff;
}

#root {
  /* display: inline-flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center; */
  /* display: inline-flex; */
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
  flex-direction: column;
  justify-content: center;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.modal-backdrop.show {
  z-index: 999;
}

.session-modal .modal-content {
  border-radius: 20px;
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
th,
td {
  vertical-align: middle !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
