.recent-development-tabs {
  padding-top: 15px;
}
.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}

@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: '';
    display: block;
    border-top: 0.25rem dotted #3b82f6;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: '';
    display: block;
    border-top: 0.25rem dotted #3b82f6;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    top: 0.3125rem;
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6;
}

.timeline-steps .timeline-content .inner-circle:before {
  content: '';
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: 0.5;
}
