.income-main {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px 0;
  width: 100%;
  display: inline-block;
}
.income-main table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  border: none;
}
.income-main table thead tr th {
  border-top: none;
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  border-bottom: 1px solid var(--sharp-border-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  text-align: var(--table-heading-block-text-align);
  vertical-align: middle;
  padding: var(--table-heading-block-inner-space);
  color: var(--table-heading-block-color);
}
.dashboard-main.dark .income-main table thead tr th {
  color: #ffffff;
}
.income-main table thead tr th:first-child {
  text-align: left;
}
.income-main table thead tr th:last-child,
.income-main table thead tr td:last-child {
  border-right: 0px;
}
.income-main table tbody tr:last-child td {
  border-bottom: 0px;
}

.income-main table tbody tr td {
  border-bottom: 0.25px solid var(--light-border-color);
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  font-weight: var(--table-cell-weight);
  text-align: var(--table-cell-text-align);
  border-top: 0px;
  vertical-align: middle;
  padding: var(--table-cell-inner-space);
  color: var(--table-cell-color);
}
.dashboard-main.dark .income-main table tbody tr td {
  color: #ffffff;
}
.income-main table tbody tr td:first-child {
  text-align: var(--table-label-column-text-align);
  font-weight: var(--table-label-column-font-weight);
  font-size: var(--table-label-column-font-size);
  color: var(--table-label-column-color);
}
.income-main table tbody tr td:last-child {
  border-right: none;
}
@media only screen and (max-width: 991px) {
  .income-main {
    overflow: auto;
  }
}
