/* .technical-block{
    font-size: 18px;
    border: 0.25px solid rgb(57 109 213 / 75%);
    border-radius: 5px;
    margin: 10px auto 0;
    width: 100%;
    display: inline-block;
}
.technical-block .title-block h4{
    color: #484848;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 0.1em solid rgb(57 109 213 / 75%);
    padding: 12px;
    margin: 0;
    text-transform: capitalize;
}
.dashboard-main.dark .technical-block .title-block h4{
    color: #ffffff;
}
.technical-block{
    overflow: hidden;
}
.technical-block .data-block{
    background: #ffffff;
}
.technical-block .data-block .technical-chart{
    min-height: 370px;
    width: 100%;
}
.technical-block .data-block .technical-chart canvas{
    font-size: 10px;
} */
.comp-financial-block {
  font-size: 18px;
  /* border: 0.25px solid var(--light-border-color); */
  border-radius: 5px;
  margin: 25px 0 0;
  width: 100%;
  display: inline-block;
}

.comp-financial-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;

  text-transform: capitalize;
  border-left: none;
}

.dashboard-main.dark .comp-financial-block .title-block h4 {
  color: #ffffff;
}
.comp-financial-block .data-block .comp-financials {
  width: 100%;
  overflow: auto;
}
.comp-financial-block .data-block table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  border: none;
}
.comp-financial-block .data-block table thead tr th {
  border-top: 0px;
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  border-bottom: 0.25px solid var(--light-border-color);
  color: var(--table-heading-color);
  font-size: var(--table-heading-font-size);
  font-weight: var(--table-heading-weight);
  text-align: var(--table-heading-text-align);
  padding: 6px;
}
.comp-financial-block .data-block table thead tr th:last-child {
  border-right: 0px;
}
.dashboard-main.dark .comp-financial-block .data-block table thead tr th {
  color: #ffffff;
}
.comp-financial-block .data-block table tbody tr td {
  border-top: 0px;
  border: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  text-align: var(--table-cell-text-align);
  font-weight: var(--table-cell-weight);
  padding: var(--table-cell-inner-space);
  /* height: 35px; */
  vertical-align: middle;
  color: var(--table-cell-color);
}
.dashboard-main.dark .comp-financial-block .data-block table tbody tr td {
  color: #ffffff;
}

/* .comp-financial-block .data-block table thead tr th{
  font-weight: 700;
  color: #484848;
  padding: 12px 0.75rem;
  border: 0.25px solid rgb(57 109 213 / 75%);

} */

.comp-financial-block .data-block table tbody tr td:first-child {
  text-align: var(--table-label-column-text-align);
  font-size: var(--table-label-column-font-size);
  border: 0.25px solid var(--light-border-color);
  font-weight: var(--table-label-column-font-weight);
  color: var(--table-label-column-color);
  border-left: none;
}
.comp-financial-block .data-block table thead tr th:last-child {
  border-right: 0px;
}
.comp-financial-block .data-block table tbody tr:last-child td {
  border-bottom: 0px;
}
.comp-financial-block .data-block table tbody tr td:last-child {
  border-right: 0px;
}
.industry-avg-block {
  font-size: 18px;
  /* border: 0.25px solid var(--light-border-color); */
  border-radius: 5px;
  margin: 25px 0 0;
  width: 100%;
  display: inline-block;
}
.industry-avg-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  /* border-bottom: 1px solid var(--sharp-border-color); */
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
  border-left: none;
}
.dashboard-main.dark .industry-avg-block .title-block h4 {
  color: #ffffff;
}
.industry-avg-block .data-block .industry-avg {
  width: 100%;
  height: 100%;
}
.industry-avg-block .data-block table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  border: none;
}
.industry-avg-block .data-block table thead tr th {
  border-top: 0px;
  /* border-right: 0.25px solid #3969D585 !important; */
  border-collapse: collapse;
  border-bottom: 0.25px solid var(--light-border-color) !important;
  font-size: var(--table-heading-font-size);
  font-weight: var(--table-heading-weight);
  text-align: var(--table-heading-text-align);
  /* height: 36px; */
  padding: var(--table-heading-inner-space);
}
.industry-avg-block .data-block table thead tr th:last-child {
  border-right: 0px;
}
.industry-avg-block .data-block table tbody tr td {
  border-top: 0px;
  border-bottom: 0.25px solid var(--light-border-color) !important;
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  font-weight: var(--table-cell-weigt);
  text-align: var(--table-cell-text-align);
  padding: var(--table-cell-inner-space);
  vertical-align: middle;
  color: var(--table-cell-color);
}
.dashboard-main.dark .industry-avg-block .data-block table tbody tr td {
  color: #ffffff;
}
.industry-avg-block .data-block table tbody tr:last-child td {
  border-bottom: 0px !important;
}
.industry-avg-block .data-block table tbody tr td:first-child {
  border-top: 0px;
  /* border: 0.25px solid rgb(57 109 213 / 75%); */
  border-collapse: collapse;
  font-size: var(--table-label-column-font-size);
  text-align: var(--table-label-column-text-align);
  font-weight: var(--table-label-column-weight);
  padding: 6px;
  /* height: 35px; */
  vertical-align: middle;
  color: var(--table-label-column-color);
  border-right: none;
}

/* .industry-avg-block .data-block table tbody tr{
    border: 0.25px solid rgb(57 109 213 / 75%);
} */

.industry-avg-block .data-block table tbody tr td:last-child {
  border-right: 0px;
  text-align: right;
  font-weight: var(--table-cell-weight);
}
.settlement-block {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px auto 0;
  width: 100%;
  display: inline-block;
}
.settlement-block .title-block {
  max-height: 50px;
}
.settlement-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .settlement-block {
  background-color: #fff;
}
/* .dashboard-main.dark .settlement-block .title-block{
    background: #222222;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
} */
.dashboard-main.dark .settlement-block .title-block h4 {
  color: var(--table-heading-block-color);
}
.settlement-block .data-block {
  height: calc(100% - 50px);
  background-color: #ffffff;
}
.settlement-block .data-block .settlement-chart {
  min-height: 205px;
  width: 100%;
}
.settlement-block .data-block .settlement-chart canvas {
  font-size: 10px;
  /* padding: 2px; */
}
.ffl-block {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px auto 0;
  width: 100%;
  display: inline-block;
}
.ffl-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
  height: 32px;
  line-height: 1;
}
.dashboard-main.dark .ffl-block {
  background-color: #ffffff;
}
/* .dashboard-main.dark .ffl-block .title-block{
    background: #222222;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
} */
.dashboard-main.dark .ffl-block .title-block h4 {
  color: var(--table-heading-block-color);
}
.dashboard-main.dark .ffl-block .data-block {
  background: #ffffff;
}
.ffl-block .data-block .ffls-chart {
  min-height: 450px;
  width: 100%;
}
.ffl-block .data-block .ffls-chart canvas {
  font-size: 10px;
  /* padding: 2px; */
}
.cirdci-block .col-lg-4 {
  max-height: 600px;
  min-height: 600px;
}
.cirdci-block .company-intro {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 10px auto 0;
  width: 100%;
  /* height: 600px; */
  height: 100%;
  overflow-y: hidden;
  display: inline-block;
}
.cirdci-block .company-intro .title-block {
  display: inline-block;
  width: 100%;
  height: 38px;
}
.cirdci-block .company-intro .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .cirdci-block .company-intro .title-block h4 {
  color: #ffffff;
}
.cirdci-block .company-intro .data-block {
  padding: 15px;
  height: calc(100% - 38px);
  overflow: hidden;
  font-size: 14px;
  color: var(--table-cell-color);
}
.cirdci-block .company-intro .data-block blockquote p::before {
  content: '\201C';
}
.cirdci-block .company-intro .data-block blockquote p::after {
  content: '\201D';
}
.cirdci-block .company-intro .data-block h6 {
  color: var(--table-heading-color);
  font-size: 14px;
  font-weight: 700;
  margin: 0px auto 15px;
  /* padding-left: 10px; */
}
.dashboard-main.dark .cirdci-block .company-intro .data-block h6 {
  color: #ffffff;
  margin-top: 0;
}
.cirdci-block .company-intro .data-block p {
  color: var(--light-black);
  font-size: 14px;
  font-weight: 400;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block p {
  color: #ffffff;
  margin-top: 0 !important;
}
.cirdci-block .company-intro .data-block b {
  color: var(--table-heading-color);
  font-size: 14px;
  font-weight: 700;
  margin: 15px auto;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block b {
  color: #ffffff;
}
.cirdci-block .company-intro .data-block ul {
  padding-left: 15px;
  margin-bottom: 30px !important;
  list-style: none;
}
.cirdci-block .company-intro .data-block ul li {
  position: relative;
}
.cirdci-block .company-intro .data-block ul li:before {
  position: absolute;
  content: '';
  left: -10px;
  top: 10px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #484848;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block ul li:before {
  background-color: #ffffff;
}
.cirdci-block .company-intro .data-block ul li p {
  color: var(--table-cell-color);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block ul li p {
  color: #ffffff;
  margin-bottom: 10px;
}
.financial-chart-block .financial-chart {
  font-size: 18px;
  /* border: 0.25px solid #3969D585; */
  border-radius: 5px;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  display: inline-block;
}
/* .financial-chart-block .financial-chart > .title-block{
    margin-bottom: 5px;
} */
.financial-chart-block .financial-chart > .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-radius: 5px 5px 0 0;
  /* border: 0.25px solid var(--light-border-color); */
  /* border-bottom: 1px solid var(--sharp-border-color); */
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .financial-chart-block .financial-chart > .title-block h4 {
  color: #ffffff;
}
.financial-chart-block .data-block .chart-title {
  text-align: center;
  margin: 10px auto;
  /* text-transform: capitalize; */
  color: var(--light-black);
  font-size: 14px;
  font-weight: 500;
  min-height: 21px;
}
.dashboard-main.dark .financial-chart-block .data-block .chart-title {
  color: #ffffff;
}
.financial-chart-block .data-block .chart-block canvas {
  font-size: 10px;
  /* padding: 2px; */
}
.financial-chart-block .data-block .slick-slider .slick-list .slick-slide {
  text-align: center;
}
.financial-chart-block .data-block .slick-slider .slick-arrow {
  background: #396dd578;
  border-radius: 5px;
  box-shadow: 0 0 5px #dfdfdf;
  z-index: 99999;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  transition: 0.2s all ease-in-out;
}
.financial-chart-block .data-block .slick-slider .slick-arrow:hover {
  opacity: 1;
  transition: 0.2s all ease-in-out;
}
.financial-chart-block .data-block .slick-slider .slick-arrow.slick-next {
  right: -5px;
}
.financial-chart-block .data-block .slick-slider .slick-arrow.slick-prev {
  left: -5px;
}
.financial-chart-block .data-block .slick-slider .slick-arrow.slick-prev:before,
.financial-chart-block
  .data-block
  .slick-slider
  .slick-arrow.slick-next:before {
  display: none;
}
/* .financial-chart-block .data-block .slick-slider .slick-arrow svg{
    position: absolute;
    left: 0;
} */
/* .dashboard-main.dark .financial-chart-block .data-block .slick-slider .slick-arrow svg path{
    fill: #ffffff;
} */
.financial-chart-block .data-block .slick-slider .slick-arrow svg path {
  /* fill: #484848; */
  fill: #ffffff;
}
.financial-chart-block .finance-income {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px auto 0;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.financial-chart-block .finance-income .title-block h4 {
  color: var(--light-black);
  font-size: var(--table-heading-block-font-size);
  font-weight: 500;
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.financial-chart-block .finance-income .data-block {
  padding: 10px !important;
}
.financial-chart-block .finance-income .data-block .chart-block {
  display: inline-block;
  min-width: calc(100% - 50px);
  min-height: 270px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid var(--light-border-color);
}

.financial-chart-block .finance-balance {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 5px auto;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.financial-chart-block .finance-balance .title-block h4 {
  color: #484848;
  font-size: 17px;
  font-weight: 600;
  border-bottom: 1px solid var(--sharp-border-color);
  padding: 10px;
  margin: 0;
  text-transform: capitalize;
}
.financial-chart-block .finance-balance .data-block {
  padding: 10px !important;
  /* background: #73a9925e; */
}
.financial-chart-block .finance-balance .data-block .chart-block {
  display: inline-block;
  min-width: calc(100% - 30px);
  min-height: 300px;
  box-shadow: 0 0 10px #67b7dc;
  border-radius: 5px;
  background-color: #ffffff;
}

.financial-chart-block .finance-ratio {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 5px auto;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.financial-chart-block .finance-ratio .title-block h4 {
  color: #484848;
  font-size: 17px;
  font-weight: 600;
  border-bottom: 1px solid var(--sharp-border-color);
  padding: 10px;
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .financial-chart-block .finance-income .title-block h4 {
  color: #ffffff;
}
.dashboard-main.dark .financial-chart-block .finance-ratio .title-block h4 {
  color: #ffffff;
}
.dashboard-main.dark .financial-chart-block .finance-balance .title-block h4 {
  color: #ffffff;
}
.financial-chart-block .finance-ratio .data-block {
  padding: 10px !important;
}
.financial-chart-block .finance-ratio .data-block .chart-block {
  display: inline-block;
  min-width: calc(100% - 30px);
  min-height: 300px;
  box-shadow: 0 0 10px #67b7dc;
  border-radius: 5px;
  background-color: #ffffff;
}
@media only screen and (max-width: 991px) {
  /* .technical-block .data-block .technical-chart{
        min-height: 350px;
    } */
}

.left {
  float: left;
}

.right {
  float: right;
}
#ffls .am5exporting-menu a.am5exporting-valign-top {
  top: 3px !important;
}
#setttlement-volume-s2,
#setttlement-value-s2 {
  height: 409px !important;
}
