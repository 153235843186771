.App-header{
    width: 100%;
    /* height: 50px; */
    min-height: 50px!important;
    background-color: transparent!important;
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 999999;
}
.App-header > div{
    width: 50%;
}
.App-header div .jZkBQk > .wrapper{
    border-radius: 7px;
    border: 1px solid #3969D5;
}