@keyframes moveToLeft {
  0% {
    /* transform: translateX(0px); */
    transform: rotate(0deg) translateX(50px) rotate(0deg);
    background: linear-gradient(
      to right,
      #0278f6 0%,
      #0278f6 0%,
      #0278f6 21%,
      #24c8dd 52%,
      #24c8dd 78%,
      #24c8dd 100%
    );
  }
  25% {
    transform: rotate(90deg) translateX(50px) rotate(90deg);
    background: linear-gradient(120deg, #0278f6 0%, #24c8dd 100%);
  }
  50% {
    transform: rotate(180deg) translateX(50px) rotate(180deg);
    background: linear-gradient(to top, #24c8dd 0%, #0278f6 100%);
  }

  75% {
    transform: rotate(270deg) translateX(50px) rotate(270deg);
    background: linear-gradient(120deg, #0278f6 0%, #24c8dd 100%);
  }
  100% {
    transform: rotate(360deg) translateX(50px) rotate(360deg);
  }
}

.mb {
  margin-bottom: 25px;
}
.mt {
  margin-top: 25px;
}
.loading-div {
  display: inline-flex;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 9999999;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.loading-div .parent-loader {
  width: 100px;
  height: 100px;
  position: relative;
  left: 50px;
  top: 50px;
  border-radius: 50%;
  border: 3px solid;
  border-color: rgb(57 109 213 / 75%);
}
.loading-div .parent-loader .elem {
  position: absolute;
  top: 25px;
  left: 25px;
  background: #24c8dd;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: moveToLeft 1s linear infinite;
  animation-delay: 1000ms;
  transform: rotate(45deg) translateX(15px);
}
.loading-div .parent-loader span {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 100%;
  text-align: center;
  color: rgb(57 109 213 / 75%);
  font-family: inherit;
}

.dashboard-main {
  padding: 0 50px;
}
.dashboard-main.dark {
  background: #222222;
  color: #ffffff !important;
}
.main-box.main-title-dashboad {
  margin: 0 0 0;
  display: inline-block;
  width: 100%;
  line-height: 1;
}
.main-box.main-title-dashboad h1 {
  color: #0b254a;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
  padding-bottom: 0px;
}
.dashboard-main.dark .main-box.main-title-dashboad h1 {
  color: rgb(57 109 213) !important;
}
.main-box.main-title-dashboad h1 .symbol {
  margin: 0 15px 0 5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
}
.main-box.main-title-dashboad h1 .sector {
  margin-left: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  color: #adadad;
  padding-bottom: 20px;
}
.dashboard-main.dark .main-box.main-title-dashboad h1 .sector {
  color: #ffffff;
}
.valuation-box.main-box {
  /* font-size: 18px; */
  /* border: 0.25px solid var(--light-border-color); */
  /* border-bottom: 0px; */
  border-radius: 5px;
  /* margin: 0px auto 5px; */
  width: 100%;
  display: inline-block;
}
.valuation-box.main-box .title-block h1 {
  width: 100%;
  line-height: 1;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 10px;
  border-bottom: 2px solid rgb(57 109 213 / 75%);
  color: rgb(57 109 213 / 75%);
  text-transform: capitalize;
}
.valuation-box.main-box .title-block h1 span.sector {
  color: #000000;
  /* font-weight: 400; */
  text-transform: capitalize;
}
.valuation-box.main-box .title-block h6 {
  padding-left: var(--table-heading-block-inner-space);
}
.valuation-box.main-box .title-block h4,
h6 {
  color: var(--table-heading-block-color);
  /* font-size: var(--table-heading-block-font-size); */
  font-size: 16px;
  font-weight: var(--table-heading-block-weight);
  /* border-bottom: 1px solid var(--sharp-border-color); */
  /* padding-left: var(--table-heading-block-inner-space); */
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .valuation-box.main-box .title-block h4 {
  color: #ffffff;
}
.valuation-box .data-block .high-low-current {
  /* border-bottom: 0.25px solid var(--light-border-color); */
  height: 100%;
}
.valuation-box .data-block .high-low-current > div:first-child {
  border-right: 0.25px solid var(--light-border-color);
}
/* .valuation-box .data-block .high-low-current > div:last-child{
    padding: 0;
}
.valuation-box .data-block .high-low-current > div:last-child */
.valuation-box .data-block .current-inner {
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.valuation-box .data-block .current-inner .icon {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  background: #67dbdb;
  justify-content: center;
  border-radius: 5px;
}
.valuation-box .data-block .current-inner > div p.text {
  width: 100%;
  line-height: 1;
  padding: 0 10px;
  font-size: 13px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-main.dark .valuation-box .data-block .current-inner > div p.text {
  color: #ffffff;
}
.valuation-box .data-block .current-inner > div p span.plus {
  color: #57870b !important;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}
.valuation-box .data-block .current-inner > div p span.minus {
  color: #ff0000 !important;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}
.dashboard-main.dark
  .valuation-box
  .data-block
  .current-inner
  > div
  p
  span.minus {
  color: #ff0000 !important;
}
.dashboard-main.dark
  .valuation-box
  .data-block
  .current-inner
  > div
  p
  span.plus {
  color: #57870b !important;
}
.valuation-box .data-block .current-inner > div p span.minus svg {
  transform: rotate(180deg);
}
.valuation-box .data-block .current-inner > div p span.percentage-change {
  font-size: 14px;
  font-weight: 400;
  padding: 0 5px;
}
.valuation-box .data-block .current-inner > div p span.percentage-change.down {
  color: #ff0000;
}
.valuation-box .data-block .current-inner > div p span.percentage-change.up {
  color: #6bab04;
}
.valuation-box .data-block .current-inner > div p.price {
  width: 100%;
  line-height: 1;
  padding: 0px 10px;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  color: #000000;
}
.dashboard-main.dark .valuation-box .data-block .current-inner > div p.price {
  color: #ffffff;
}
.valuation-box .data-block .high-low-inner {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  /* display: inline-block; */
  width: 100%;
  height: 100%;
}
.valuation-box .data-block .high-low-inner > div:first-child {
  border-bottom: 0.25px solid var(--light-border-color);
}
.valuation-box .data-block .high-low-inner div {
  padding: 10px 20px 10px 20px;
}
.valuation-box .data-block .high-low-inner div p {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}
.valuation-box .data-block .high-low-inner div p span.text {
  color: #000000;
  font-weight: 400;
  font-size: 13px;
}
.dashboard-main.dark
  .valuation-box
  .data-block
  .high-low-inner
  div
  p
  span.text {
  color: #ffffff;
}
.valuation-box .data-block .high-low-inner div p span.price {
  color: #000000;
  font-weight: 700;
}
.valuation-box .data-block .high-low-inner .high-price .icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  background: #87b9ff;
  justify-content: center;
  border-radius: 5px;
}
.valuation-box .data-block .high-low-inner .high-price > div p.text {
  width: 100%;
  line-height: 1;
  padding: 0 10px;
  font-size: 12px;
}
.valuation-box .data-block .high-low-inner .high-price > div p span {
  color: #6bab04;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}
.valuation-box .data-block .high-low-inner .high-price > div p.price {
  width: 100%;
  line-height: 1;
  padding: 0px 10px;
  font-size: 20px;
  font-weight: 400;
}
.valuation-box .data-block .high-low-inner .high-price p span.price {
  color: #57870b !important;
}
.valuation-box .data-block .high-low-inner .low-price p span.price {
  color: #e90000 !important;
}
.valuation-box .data-block .high-low-inner .low-price .icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  background: #ff8787;
  justify-content: center;
  border-radius: 5px;
}
.valuation-box .data-block .high-low-inner .low-price > div p.text {
  width: 100%;
  line-height: 1;
  padding: 0 10px;
  font-size: 12px;
}
.valuation-box .data-block .high-low-inner .low-price > div p.text span {
  color: #e90000;
}
.valuation-box .data-block .high-low-inner .low-price > div p span {
  color: #6bab04;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}
.valuation-box .data-block .high-low-inner .low-price > div p.price {
  width: 100%;
  line-height: 1;
  padding: 0px 10px;
  font-size: 20px;
  font-weight: 400;
}
.valuation-box .data-block .high-low-inner .low-price > div p.price span {
  color: #e90000;
}
.valuation-box .data-block ul {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.valuation-box .data-block ul li {
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  /* border-left: 0.25px solid var(--light-border-color); */
  border-bottom: 0.25px solid var(--light-border-color);
  color: #000000;
  font-weight: 600;
  padding: 0px 10px;
}
.valuation-box .data-block ul li.no-border {
  border-bottom: 0px;
}
.valuation-box .data-block ul li p {
  width: 100%;
  margin: 0;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
}
.valuation-box .data-block ul li data {
  font-size: 13px !important;
}
.valuation-box .data-block ul li data {
  font-size: 12px !important;
}
.dashboard-main.dark .valuation-box .data-block ul li p {
  color: #ffffff;
}
.valuation-box .data-block ul li p span.data {
  font-weight: 600;
}
.stock-data-box.main-box {
  font-size: 18px;
  /* border: 0.25px solid var(--light-border-color); */
  border-radius: 5px;
  margin: 25px auto;
  width: 100%;
  display: inline-block;
}
.stock-data-box.main-box .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  /* font-size: ; */
  font-weight: var(--table-heading-block-weight);
  /* border-bottom: 1px solid var(--sharp-border-color); */
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .stock-data-box.main-box .title-block h4 {
  color: #ffffff;
}
.stock-data-box .data-block table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  border: none;
}
.stock-data-box .data-block table thead tr th {
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  border-bottom: 0.25px solid var(--light-border-color);
  border-top: 0px;
  font-size: var(--table-heading-font-size);
  font-weight: var(--table-heading-weight);
  text-align: var(--table-heading-text-align);
  vertical-align: middle;
  color: var(--table-heading-color);
  padding: var(--table-heading-inner-space);
}

.dashboard-main.dark .stock-data-box .data-block table thead tr th {
  color: #ffffff;
}

.stock-data-box .data-block table tbody tr td {
  border-top: 10px;
  border-bottom: 0.25px solid var(--light-border-color);
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  font-weight: var(--table-cell-weight);
  text-align: var(--table-cell-text-align);
  color: var(--table-cell-color);
  vertical-align: middle;
  padding: var(--table-cell-inner-space);
}
.dashboard-main.dark .stock-data-box .data-block table tbody tr td {
  color: #ffffff;
}
.stock-data-box .data-block table tbody tr td:first-child {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}
/* .stock-data-box .data-block table thead tr th:last-child{
    border-right: 0px;    
} */
.stock-data-box .data-block > div:last-child table thead tr th:last-child,
.stock-data-box .data-block > div:last-child table tbody tr td:last-child {
  border-right: 0px;
}
.stock-data-box .data-block table tbody tr:last-child td {
  border-bottom: 0px;
}
table tr:nth-child(even) {
  background: #d2e5ff;
}
table {
  border-radius: 2px 2px 0px 0px;
}
table tr:nth-child(odd) {
  background: #e9f2ff;
}
table td {
  color: #000000 !important;
  font-size: 13px !important;
}
table th {
  background: #0b254a;
  color: #ffffff !important;
  font-size: 14px !important;
}
.high-low-current .head {
  background-color: #0b254a;
}
.current-inner .text,
.price {
  color: #ffffff !important;
}
.odd {
  background-color: #e9f2ff;
}
.even {
  background-color: #d2e5ff;
}

.block-head-bg-dark {
  background-color: #0b254a;
}

.block-head-bg-dark h4 {
  color: #ffffff !important;
}

@media only screen and (max-width: 1199px) {
  .dashboard-main {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 991px) {
  .valuation-box .data-block .high-low-current {
    height: initial;
  }
  .valuation-box .data-block .high-low-current > div:first-child {
    border: none;
    border-bottom: 0.25px solid var(--light-border-color);
  }
  /* .valuation-box .data-block ul li{
        border-left: none;
    } */
  .valuation-box .data-block ul li:nth-child(odd) {
    border-left: none;
  }
  .stock-data-box.main-box .data-block {
    overflow: auto;
  }
  .stock-data-box.main-box > div:last-child > div:first-child {
    border-bottom: 0.25px solid #3969d585;
  }
  .stock-data-box.main-box > div:last-child > div:last-child {
    margin-top: 10px;
  }
  .stock-data-box .data-block table {
    border-top: 0.25px solid #3969d585;
  }
  .stock-data-box .data-block table thead tr th:last-child,
  .stock-data-box .data-block table tbody tr td:last-child {
    border-right: none;
    border-top: 0.25px solid #3969d585;
  }
  .main-tabs-view .nav.nav-tabs {
    justify-content: center;
  }
}
