.header {
  border-bottom: 1px solid rgb(57, 109, 213, 50%);
  position: sticky;
  top: 0;
  z-index: 999;
  background: #ffffff;
}
.header.dark .navbar {
  /* background: #396dd5!important; */
  background: #222222 !important;
}
.navbar {
  background-color: #ffffff;
  height: 50px;
}
.navbar .navbar-brand img {
  max-width: 100px;
}
.header.dark .navbar .navbar-brand {
  padding: 5px 10px;
  background: #ffffff;
  border-radius: 10px;
}
.navbar .user-option {
  position: relative;
  overflow: hidden;
}
.navbar .user-option.active {
  overflow: initial;
}
.navbar .user-option .user-img {
  margin: 0;
  position: relative;
  z-index: 99999999;
}
.navbar .user-option .user-img img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}
.navbar .user-option .user-img .userInitials {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(57 109 213);
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.navbar .user-option .user-img span.user-trigger {
  cursor: pointer;
  position: relative;
}
.header.dark .navbar .user-option .user-img .userInitials {
  background-color: #ffffff;
  color: #396dd5;
}
.header.dark .navbar .user-option .user-img span.user-trigger svg path {
  fill: #ffffff;
}
.header .navbar .theme-mode button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  border: none;
  /* border: 1px solid rgb(57, 109, 213); */
}
.header.dark .navbar .theme-mode button {
  /* border: 1px solid rgb(255, 255, 255); */
}
.navbar .user-option ul {
  background: #ffffff;
  box-shadow: 0 0 10px #cecece;
  border-radius: 7px;
  position: absolute;
  min-width: 150px;
  right: 0;
  opacity: 0;
  top: -100px;
  transition: all 0.5s;
  z-index: 9999999;
}
.navbar .user-option.active ul {
  top: 30px;
  opacity: 1;
  transition: all 0.5s;
}
.navbar .user-option ul li.option-item {
  line-height: 1;
  padding: 6px 10px;
  box-shadow: 0 0 1px #ececec;
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin: 0;
}
.navbar .user-option li svg {
  position: absolute;
  top: 0px;
  left: 5px;
  z-index: 0;
  width: 15px;
  height: 15px;
}
.navbar .user-option ul li.option-item:hover {
  background: none;
}
.navbar .user-option ul li.option-item:last-child {
  border: none;
}
.navbar .user-option ul li.option-item:not(:first-child):after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: all 0.5s;
}
.navbar .user-option ul li.option-item p {
  line-height: 1;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* text-transform: capitalize; */
  color: #767676;
}
.navbar .user-option ul li.option-item:not(:first-child) p {
  cursor: pointer;
}
.navbar .user-option ul li.option-item:not(:first-child):hover:after {
  left: 0;
  background: rgb(247 247 247);
}
.navbar .user-option ul li.option-item:not(:first-child):hover p {
  transition: 0.6s all;
}
.navbar .user-option ul li.option-item p span.icon {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  background: transparent;
  display: inline-block;
}
.navbar .user-option ul li.option-item p span.icon svg {
  width: 15px;
  height: 15px;
}

.imglogo {
  width: 150px;
}

.navbar-nav .nav-item button {
  font-size: 18px;
  /* color: var(--light-black); */
  color: #000000;
  font-weight: 500;
  padding: 5px 10px;
  /* min-width: 150px; */
  background: transparent;
  background-color: #c8e945;
  margin: 0;
  font-size: 13px !important;
  border: none;
  border-radius: 5px;
}
.navbar-nav .nav-item .nav-link.active button {
  color: #ffffff;
  background-color: #0b254a;
  /* background-color: rgb(57 109 213); */
  border-radius: 5px;
}

.navbar-nav .nav-item a {
  text-decoration: none;
  border: none;
}
