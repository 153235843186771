.timeline-tab-block{
    padding: 10px 0 0;
    display: inline-block;
    width: 100%;
}
.recent-development-tab{
    font-size: 18px;
    border-radius: 5px;
    margin: 10px auto 0;
    width: 100%;
    height: 100%;
    display: inline-block;
}
.recent-development-tab .title-block h4 {
    color: #484848;
    font-size: 16px;
    font-weight: 700;
    border: 0.25px solid rgb(57 109 213 / 75%);
    padding: 12px;
    margin: 0;
    text-transform: capitalize;
}
.recent-development-tab .recent-tabs-view{
    margin: 0px;
    width: 100%;
    display: inline-block;
}
.recent-development-tab .recent-tabs-view .nav.nav-tabs{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    border: 0.25px solid rgb(57 109 213 / 75%);
    border-top: 0px; 
    border-radius: 0px;
}
.recent-development-tab .recent-tabs-view .nav.nav-tabs .nav-item button.nav-link{
    font-size: 18px;
    color: #484848;
    font-weight: 400;
    padding: 10px 20px;
    min-width: 150px;
    background: transparent;
    margin: 0;
}
.recent-development-tab .recent-tabs-view .nav.nav-tabs .nav-item button.nav-link.active{
    color: #ffffff;
    background-color: rgb(57 109 213 / 75%);
    border-radius: 5px;
}
/* .recent-development .recent-tabs-view .tab-content > .tab-pane{
    height: 0px;
    display: block;
} */
.recent-development-tab .range-selector{
    display: flex;
    align-items: center;
    padding: 30px 15px;
    border: 0.25px solid rgb(57 109 213 / 75%);
    border-top: 0px;
    margin-bottom: 30px;
}
.recent-development-tab .range-selector > span{
    color: #484848;
}
.recent-development-tab .dx-rangeslider.dx-slider.dx-trackbar{
    width: 50%;
    padding: 10px 50px 0 100px;
}
/* .range-selector .dx-trackbar-wrapper.dx-slider-wrapper.dx-swipeable .dx-slider-label{
    top: 8px;
    left: -30px;
}
.range-selector .dx-trackbar-wrapper.dx-slider-wrapper.dx-swipeable .dx-slider-label:last-child{
    right: -30px;
} */
.recent-development-tab .dx-slider-range.dx-slider-range-visible{
    border: 1px solid rgb(57 109 213 / 75%);
    background: rgb(57 109 213 / 75%)
}
.recent-development-tab .dx-rangeslider-start-handle , .recent-development-tab .dx-rangeslider-end-handle{
    top: 5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid rgb(57 109 213 / 75%)!important;
    background: #ffffff!important;
}
.recent-development-tab .timeline-data{
    display: inline-block;
    width: 100%;
}
.recent-development-tab .timeline-data table{
    /* display: inline-table; */
    width: 100%;
}
.recent-development-tab .timeline-data .year-block table tr{
    padding: 10px 0;

}
.recent-development-tab .timeline-data .year-block table tr.title td:first-child span{
    position: absolute;
    right: -8px;
    top: 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #000000;
}
.recent-development-tab .timeline-data .year-block table tr td{ 
    padding: 10px 30px 10px;  
    vertical-align: top;
    position: relative;
}
.recent-development-tab .timeline-data .year-block table tr.title td:first-child{
    position: relative;
    text-align: right;
    width: 50%;
    font-weight: 700;
    font-size: 18px;
    border: 0px;
    padding: 50px;
}
.recent-development-tab .timeline-data .year-block table tr.title td:first-child:before{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    width: 1px;
}
.recent-development-tab .timeline-data .year-block table tr.data td:first-child{
    border-right: 0.25px solid rgb(57 109 213 / 75%);
}
.recent-development-tab .timeline-data .year-block table tr.data td:nth-child(even){
    padding: 15px 15px 15px 50px !important;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
}
.recent-development-tab .timeline-data .year-block table tr.data td:nth-child(odd){
    padding: 15px 50px 15px 15px !important;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
}
.recent-development-tab .timeline-data .year-block table tr.data:nth-child(even) td span{
    position: absolute;
    left: -25px;
    top: 0px;
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 1px solid rgb(57 109 213 / 75%);
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    color: #484848
}
.recent-development-tab .timeline-data .year-block table tr.data:nth-child(odd)  td span{
    position: absolute;
    right: -25px;
    top: 0px;
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 1px solid rgb(57 109 213 / 75%);
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    color: #484848
}

.recent-development-tab .timeline-data .year-block table tr td div h6{
    color: rgb(57 109 213);
    font-size: 18px;
    text-transform: capitalize;
}
.recent-development-tab .timeline-data .year-block table tr td div p{
    color: #484848;
    font-size: 18px;
}

@media only screen and (max-width: 991px){
    .recent-development-tab .range-selector{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .recent-development-tab .dx-rangeslider.dx-slider.dx-trackbar{
        width: 100%;
        padding: 10px;
        margin-top: 10px;
    }
    .recent-development-tab .recent-tabs-view .nav.nav-tabs{
        justify-content: center;
    }
}
@media only screen and (max-width: 500px){
    .nav.nav-tabs .nav-item button.nav-link{
        padding: 10px;
        min-width: 120px;
    }
    .recent-development-tab .timeline-data .year-block table tr.title td:first-child{
        width: 100%;
        border-bottom: 1px solid #adadad;
        padding: 50px 0 10px;
        text-align: left!important;
    }
    .recent-development-tab .timeline-data .year-block table tr{
        display: flex;
        padding: 10px;
    }
    .recent-development-tab .timeline-data .year-block table tr.data td:first-child{
        border: none;
    }
    .recent-development-tab .timeline-data .year-block table tr.data.right td:first-child{
        padding: 0px!important;
    }
    .recent-development-tab .timeline-data .year-block table tr.data.right td:last-child{
        padding: 0px!important;
    }
    .recent-development-tab .timeline-data .year-block table tr.data td:nth-child(odd){
        padding: 0px!important;
    }
    .recent-development-tab .timeline-data .year-block table tr.data.left td:last-child{
        padding: 0px!important;
    }
    .recent-development-tab .timeline-data .year-block table tr td:first-child{
        /* width: 75px; */
        padding: 10px;
        font-size: 15px;
        text-align: center;
    }
    .recent-development-tab .timeline-data .year-block table tr td:last-child{
        padding-left: 20px;
        text-align: left;
        font-weight: 400;
        font-size: 15px;
    }
    .recent-development-tab .timeline-data .year-block table tr.data td div{
        display: flex;
        flex-direction: column;
    }
    .recent-development-tab .timeline-data .year-block table tr.data td div span , .recent-development-tab .timeline-data .year-block table tr.data:nth-child(odd) td span , .recent-development-tab .timeline-data .year-block table tr.data:nth-child(even) td span{
        position: relative;
        width: fit-content;
        border-radius: 5px;
        text-align: left;
        display: inline-flex;
        justify-content: flex-start;
        padding: 5px 10px;
        height: auto;
        left: 0;
        margin: 10px 0;
        align-items: center;
    }
    .recent-development-tab .timeline-data .year-block table tr td div p{
        margin-bottom: 0;
    }
}