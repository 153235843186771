.valuation-main {
  margin-bottom: 30px;
}
.valuation-main > .d-flex .col-xl-6:nth-child(odd) {
  padding-left: 0px !important;
}
.valuation-block {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px auto 0;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.valuation-block .title-block {
  border-bottom: 1px solid var(--sharp-border-color);
  /* padding: 12px; */
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.dashboard-main.dark .valuation-block {
  background-color: #ffffff;
}
.valuation-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  margin: 0;
  padding: var(--table-heading-block-inner-space);
  text-transform: capitalize;
  display: inline-block;
  width: 100%;
}
/* .dashboard-main.dark .valuation-block .title-block h4{ */
/* color: #ffffff; */
/* } */
.valuation-block .title-block p {
  display: inline-block;
  width: 100%;
  color: #484848;
  font-size: 12px;
  font-weight: 400;
  margin: 0px auto;
}
.valuation-block .data-block .valuation-chart {
  min-height: 400px;
  width: 100%;
  background: #ffffff;
}
/* .valuation-block .data-block .valuation-chart div{
    display: inline-block;
    width: 100%;
    height: 100%;
} */
.valuation-block .data-block .valuation-chart canvas {
  font-size: 10px;
  /* padding: 2px; */
}

a.am5exporting-icon {
  display: block;
  overflow: hidden;
  text-indent: -13333337px;
  width: 25px;
  height: 25px;
  padding: 0;
  background-repeat: no-repeat;
  background-image: url(../../../assets/media/images/valuation-download.jpg) !important;
  background-color: #fff;
  background-position: center;
  margin: -32px 10px 0 0;
}

a.am5exporting-icon,
.am5exporting-list {
  display: inline-block;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 0.5em;
  background: #ececec;
  cursor: pointer;
}
