.industry-average-table .title-block h4 {
    color: var(--table-heading-block-color);
    font-size: var(--table-heading-block-font-size);
    font-weight: var(--table-heading-block-weight);
    border-bottom: 1px solid var(--sharp-border-color);
    padding: var(--table-heading-block-inner-space);
    margin: 0;

    text-transform: capitalize;
    border-left: none;
}

.industry-average-table .data-block table tbody tr td:first-child {
    text-align: var(--table-label-column-text-align);
    font-size: var(--table-label-column-font-size);
    border: 0.25px solid var(--light-border-color);
    font-weight: var(--table-label-column-font-weight);
    color: var(--table-label-column-color);
    border-left: none;
}

.industry-average-table .data-block table tbody tr td{
    border-top: 0px;
    border: 0.25px solid var(--light-border-color);
    border-collapse: collapse;
    font-size: var(--table-cell-font-size);
    text-align: var(--table-cell-text-align);
    font-weight: var(--table-cell-weight);
    padding: var(--table-cell-inner-space);
    /* height: 35px; */
    vertical-align: middle;
    color: var(--table-cell-color);
}