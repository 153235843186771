.notification-main{
    padding: 0 60px;
}
.notification-main.p-0{
    padding: 0;
}
.notification{
    padding: 10px;
    width: 100%;
    margin: 10px auto 0;
    position: relative;
    border-radius: 7px;
    transition: all 0.5s;
}
.notification p{
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
}
.notification button{
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
    position: absolute;
    right: 15px;
}
.notification button svg{
    width: 100%;
    height: 100%;
}