.research-main .comp-introduction-block p {
  color: #3d3d3d;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 142.5%;
  left: 89px;
  top: 486px;
  margin: 0px 10px;
  padding: 10px 0px;
  /* bottom: 10px !important; */
}

.revenue-drivers-block .data-block .item {
  padding: 10px 0px;
  background-color: #e9f2ff;
  color: #3d3d3d;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 142.5%;
  margin: 10px 0px;
  left: 10px;
  /* margin: ; */
  /* position: absolute; */
  /* padding: 10px 10px; */
}

.item .text {
  padding-left: 10px;
  margin: auto;
  /* margin-top: 0; */
}
.item .text p {
  margin: auto;
  /* margin-top: 0 !important; */
}
.item .icon {
  margin: auto;
  padding: 0px 22px;
}
.data-block .image-container {
  /* position: absolute; */
  position: relative;
  padding: 0px 20px;
}

.item .icon .img1 {
  position: absolute;
  z-index: 2;
  left: 55.67%;
  right: 16.67%;
  top: 24.33%;
  bottom: 8.33%;
}
.item .icon .img2 {
  /* position: absolute; */
  z-index: 1; /* Place this image in front of the img1 */
  position: absolute;
  right: 11px;
  /* top: -4.5px; */
  /* right: 11.5px; */
}

.revenue-drivers-block .data-block {
  margin: 10px 0px;
  padding: 10px 0px;
}
.comp-introduction-block .data-block {
  background-color: #f8f8f8;
  /* padding: 10px 10px; */
  /* margin: 10px 0px; */
}
.comp-introduction-block,
.publish-report-block,
.revenue-drivers-block {
  font-size: 18px;
  /* border: 0.25px solid var(--light-border-color); */
  border-radius: 5px;
  margin: 25px auto 0;
  width: 100%;
  display: inline-block;
}
.research-main .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
  height: 32px;
  line-height: 1;
}

.report img {
  /* position: absolute; */
  width: 100px;
  height: 101px;
  left: 836px;
  top: 486px;

  /* background: #cdd9f8; */
  border-radius: 4.71836px;
  margin: auto;
}
.report .content {
  /* position: absolute; */
  width: 435px;
  /* height: 56px; */
  left: 1007px;
  top: 486px;
  padding: 5px 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-transform: capitalize;
}
.report .calendar-icon {
  /* width: 96px; */
  height: 11px;
  left: 1018.16px;
  top: 548px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11.3241px;
  line-height: 100%;
}
.report .calendar-icon span {
  padding: 5px;
}
.report .description1 {
  /* width: 303px; */
  /* height: 42px; */
  left: 1007px;
  top: 565px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14.1551px;
  line-height: 150%;
  padding-top: 8px;
}
.report {
  display: flex;
  padding: 10px 0px 0px 0px;
}
.view-all {
  text-decoration-line: underline;

  color: #0b254a;
}
.report .pdficon {
  padding: 5px;
}

.report .content div {
  padding-bottom: 8px;
}
.recent-development-tabs button {
  min-width: auto !important;
}
.rd-view {
  /* font-size: 18px; */
  /* border: 0.25px solid #3969D5; */
  /* border-radius: 5px; */
  margin: 5px auto;
  width: 100%;
  display: inline-block;
}

.time-line-block .timeline {
  /* padding: 10px 0px; */
  /* background-color: #e9f2ff; */
  color: #3d3d3d;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 142.5%;
  margin: 10px 0px;
  left: 10px;
  /* margin: ; */
  /* position: absolute; */
  /* padding: 10px 10px; */
}

.timeline .text {
  padding-left: 10px;
  margin: auto;
  /* margin-top: 0; */
}
.timeline .text p {
  margin: auto;
  padding: 0px 22px;
  /* margin-top: 0 !important; */
}
.timeline .timeline-date {
  /* width: 69px; */
  height: 21px;
  left: 888px;
  top: 1055px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 176.5%;
  /* or 21px */

  color: #929292;
}
.timeline .icon {
  margin: auto;
  padding: 0px 19px;
}

.chain {
  padding-left: 0px;
}
.chain li {
  display: flex;
  align-items: center;
  padding: 20px 0;
  /* position: relative; */
}
.chain li::after {
  content: '';
  height: 50%;
  width: 1px;
  bottom: 79px;
  left: 36px;
  position: absolute;
  border: 1px dashed #a3a3a3;
}

.chain li.last::after {
  display: none;
}

.chain li span {
  width: 75px;
  height: 75px;
  flex: 0 0 75px;
  display: grid;
  place-items: center;
  font-size: 45px;
  background-color: #fbc0ff;
  color: #730c7f;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.chain li div {
  /* padding-left: 35px; */
}
.chain li div h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #868686;
}
/* .chain li div p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    color: #000;
  } */

.timeline .text .timeline-date {
  /* width: 69px; */
  height: 21px;
  left: 888px;
  top: 1055px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 176.5%;
  /* or 21px */

  color: #929292;
}
