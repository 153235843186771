.cashflow-main {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 25px 0;
  width: 100%;
  display: inline-block;
}

.cashflow-main table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  border: none;
}

.cashflow-main table thead tr th {
  border-top: none;
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  border-bottom: 1px solid var(--sharp-border-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  text-align: var(--table-heading-block-text-align);
  padding: var(--table-heading-block-inner-space);
  color: var(--table-heading-block-color);
}
.dashboard-main.dark .cashflow-main table thead tr th {
  color: #ffffff;
}
.cashflow-main table thead tr th:first-child {
  text-align: left;
}
.cashflow-main table thead tr th:last-child,
.cashflow-main table thead tr td:last-child {
  border-right: 0px;
}
.cashflow-main table tbody tr:last-child td {
  border-bottom: 0px;
}
.cashflow-main table tbody tr.bg-td {
  background-color: var(--light-gray);
  font-weight: 700;
}
.cashflow-main table tbody tr.bg-td td {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--light-black);
  font-weight: 00;
  text-transform: capitalize;
}
.cashflow-main table tbody tr.bold {
  font-weight: 700;
}
/* .cashflow-main table tbody tr.bold td:first-child{
    font-weight: 800;
    font-size: var(--table-label-column-font-size);
    color: var(--table-label-column-color);
} */
.cashflow-main table tbody tr.bold td {
  font-weight: 800 !important;
  font-size: var(--table-label-column-font-size);
  color: var(--table-label-column-color);
}
.cashflow-main table tbody tr td {
  border-bottom: 0.25px solid var(--light-border-color);
  border-right: 0.25px solid var(--light-border-color);
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  font-weight: var(--table-cell-weight);
  text-align: var(--table-cell-text-align);
  border-top: 0px;
  padding: var(--table-cell-inner-space);
  /* font-weight: 600; */
  color: #484848;
}
.dashboard-main.dark .cashflow-main table tbody tr td {
  color: #ffffff;
}
.cashflow-main table tbody tr td:first-child {
  text-align: var(--table-label-column-text-align);
  font-weight: var(--table-label-column-weight);
  font-size: var(--table-label-column-font-size);
}
.cashflow-main table tbody tr td:last-child {
  border-right: none;
}
@media only screen and (max-width: 991px) {
  .cashflow-main {
    overflow: auto;
  }
}
